exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/Map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-old-map-js": () => import("./../../../src/pages/OldMap.js" /* webpackChunkName: "component---src-pages-old-map-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/Search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-timeline-tsx": () => import("./../../../src/pages/Timeline.tsx" /* webpackChunkName: "component---src-pages-timeline-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-content-index-js": () => import("./../../../src/templates/ContentIndex.js" /* webpackChunkName: "component---src-templates-content-index-js" */),
  "component---src-templates-lore-js": () => import("./../../../src/templates/Lore.js" /* webpackChunkName: "component---src-templates-lore-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/News.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

