import React from "react"
import { navigate } from "gatsby"

const SearchField = () => {
  const createQuery = search => {
    navigate("/Search/", { state: { search } })
  }

  return (
    <div className="search-field">
      <label htmlFor="search" className="visually-hidden">
        Search lore and articles
      </label>
      <input
        type="search"
        name="search"
        required
        placeholder="Search"
        className="search-input"
        onKeyPress={e => {
          if (e.key === "Enter") {
            createQuery(e.target.value)
            e.target.value = ""
          }
        }}
      />
    </div>
  )
}

export default SearchField
