import React, { useState } from "react"
import MenuItems from "./MenuItems"

export default function HamburgerMenu() {
  const [isActive, setActive] = useState(false)

  // Toggles the "transform" class which activates the hamburger menu on mobile.
  const handleToggle = () => {
    setActive(!isActive)
  }

  return (
    <nav
      id="hamburger"
      className={`menu ${isActive ? "transform" : ""}`}
      role="navigation"
      onClick={handleToggle}
      onKeyDown={handleToggle}
    >
      <span />
      <span />
      <span />
      <ul className={`box ${isActive ? "transform" : ""}`}>
        <MenuItems />
      </ul>
    </nav>
  )
}
