import React from "react"
import Link from "gatsby-link"
import MenuItems from "./MenuItems"

export default function Menu() {
  return (
    <>
      <div id="logoBanner" role="banner">
        <Link to="/" id="logo" title="Go to main page" />
      </div>
      <nav id="stdMenu" className="menu" role="navigation">
        <ul className="box">
          <MenuItems />
        </ul>
      </nav>
    </>
  )
}
