import React from "react"
import { Link } from "gatsby"

const MenuItems = () => (
  <>
    <li>
      <Link to="/">Main Page</Link>
    </li>
    <li>
      <Link to="/About">About</Link>
    </li>
    <li>
      <Link to="/Contents">Contents</Link>
    </li>
    <li>
      <Link to="/Article">Articles</Link>
    </li>
    <li>
      <Link to="/Lore">Lore</Link>
    </li>
    <li>
      <Link to="/News">News</Link>
    </li>
    <li>
      <Link to="/Map">Maps</Link>
    </li>
    <li>
      <Link to="/Timeline">Timeline</Link>
    </li>
  </>
)

export default MenuItems
