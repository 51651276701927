import React from "react"
import Layout from "./src/components/Layout"

export function wrapPageElement({ element, props }) {
  if (
    !props.location.pathname.includes("Map") &&
    !props.location.pathname.includes("Timeline")
  ) {
    return <Layout {...props}>{element}</Layout>
  }
}
